import React from 'react';
// import { Link } from "gatsby"
// import logo from './membisnis.png'
class Footer extends React.Component {

    render() {
        return (
            <>
            
             <div className="container">
               <div className="row">
                <div className="col-lg-4 col-md-12 sidenav">
            </div>
                <div className="col-lg-8 p1050">
                    <div className="fht">
	                <p><b>Pengungkapan Risiko:</b> Perdagangan instrumen finansial dan/atau mata uang kripto membawa risiko tinggi, termasuk risiko kehilangan sebagian atau seluruh nilai investasi Anda, dan mungkin tidak sesuai untuk sebagian investor. Harga mata uang kripto amat volatil dan dapat dipengaruhi oleh berbagai faktor eksternal seperti peristiwa finansial, regulasi, atau politik. Trading dengan margin meningkatkan risiko finansial. Sebelum memutuskan untuk memperdagangkan instrumen finansial atau mata uang kripto, Anda harus sepenuhnya memahami risiko dan biaya terkait perdagangan di pasar finansial, mempertimbangkan tujuan investasi, tingkat pengalaman, dan selera risiko Anda dengan cermat, serta mencari saran profesional apabila dibutuhkan.</p>
                    </div>
                </div>
                </div>
                </div>
           
            </>
        )
    }
}

export default Footer;